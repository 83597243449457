import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

const PreviewCompatibleImage = ({
  imageInfo, imageClasses, imageStyle, shouldObjectFitContain
}) => {
  const { alt = '', childImageSharp, image } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return (
      <Img className={ imageClasses } imgStyle={ shouldObjectFitContain ? { objectFit: 'contain' } : { } } style={ imageStyle } fluid={ image.childImageSharp.fluid } alt={ alt } />
    );
  }

  if (childImageSharp) {
    return <Img className={ imageClasses } imgStyle={ shouldObjectFitContain ? { objectFit: 'contain' } : { } } style={ imageStyle } fluid={ childImageSharp.fluid } alt={ alt } />;
  }

  const previewStyles = {
    ...imageStyle,
    objectFit: 'cover',
    objectPosition: '50% 50%'
  };

  if (image) return <img style={ previewStyles } className={ imageClasses } src={ image } alt={ alt } />;

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
  }).isRequired,
  imageClasses: PropTypes.string,
  imageStyle: PropTypes.objectOf(PropTypes.string),
  shouldObjectFitContain: PropTypes.string
};

PreviewCompatibleImage.defaultProps = {
  imageClasses: '',
  shouldObjectFitContain: false,
  imageStyle: {}
};

export default PreviewCompatibleImage;